import find from 'lodash/find';
import { prepareRestApiMetaData } from '@/utils/metaDataHelpers';
import type { CdekComponentParam } from '@/types/dto/cdekComponent';
import type ICdekComponent from '@/types/dto/cdekComponent';

import type { IWebsitePageData } from '@/types/dto/restWebsitePage';

export const regexpTemplate = /(\{{.*?}})/;
export const regexpOnlyBrTemplate = /^<br ?\/>\r\n$/;

export function findComponentByPlace(templatePlace: string, components: ICdekComponent[]) {
  const place = parseFloat(templatePlace.replace('{{', '').replace('}}', ''));
  return find(components, { place });
}

function convertPageArrayToObject(params: CdekComponentParam[]) {
  if (params && params.length) {
    let object: Record<string, any> = {};

    for (const {
      name,
      value,
      paginated,
      city,
      cities,
      item,
      file,
      items,
      complex,
      array,
      link,
      slides,
      image,
      columnLeft,
      columnRight,
      boolean,
      list,
      screens,
      errors,
      filesScreens,
      sizes,
      images,
      inputs,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __typename,
    } of params) {
      try {
        switch (__typename) {
          case 'WebsitePageComponentParamString':
            object[name] = value;
            break;
          case 'WebsitePageComponentParamCity':
            object[name] = city;
            break;
          case 'WebsitePageComponentParamFile':
            object[name] = file;
            break;
          case 'WebsitePageComponentParamOfficeOne':
          case 'WebsitePageComponentParamNewItem':
          case 'WebsitePageComponentParamPressNewItem':
            object[name] = item;
            break;
          case 'WebsitePageComponentParamArrayString':
            object[name] = array;
            break;
          case 'WebsitePageComponentParamFeedback':
          case 'WebsitePageComponentParamDocument':
          case 'WebsitePageComponentParamQuestion':
          case 'WebsitePageComponentParamContact':
          case 'WebsitePageComponentParamOffice':
          case 'WebsitePageComponentParamNew':
          case 'WebsitePageComponentParamPressNew':
          case 'WebsitePageComponentParamGroupTariff':
          case 'WebsitePageComponentParamPartner':
          case 'WebsitePageComponentParamService':
          case 'WebsitePageComponentParamVideos':
          case 'WebsitePageComponentParamTile':
          case 'WebsitePageComponentParamHistory':
          case 'WebsitePageComponentParamGroupTop':
          case 'WebsitePageComponentParamAlert':
          case 'WebsitePageComponentParamPartnerOffer':
          case 'WebsitePageComponentParamPartnerCarousel':
          case 'WebsitePageComponentParamSocialLink':
            object[name] = paginated;
            break;
          case 'WebsitePageComponentParamComplex':
            object[name] = complex;
            break;
          case 'WebsitePageComponentParamQuestionsCategory':
          case 'WebsitePageComponentParamAccreditationCategory':
          case 'WebsitePageComponentParamTabs':
          case 'WebsitePageComponentParamLinkBlock':
          case 'WebsitePageComponentParamBanners':
          case 'WebsitePageComponentParamActionsCarousel':
            object[name] = items;
            break;
          case 'WebsitePageComponentParamLink':
            object[name] = link;
            break;
          case 'WebsitePageComponentParamImage':
            object[name] = image;
            break;
          case 'WebsitePageComponentParamSlides':
            object[name] = slides;
            break;
          case 'WebsitePageComponentParamTwoColumnsBackground':
            object = {
              columnLeft,
              columnRight,
            };
            break;
          case 'WebsitePageComponentParamCities':
            object[name] = cities;
            break;
          case 'WebsitePageComponentParamBoolean':
            object[name] = boolean;
            break;
          case 'WebsitePageComponentParamList':
          case 'WebsitePageComponentParamPopularProduct':
            object[name] = list;
            break;
          case 'WebsitePageComponentParamScreenColumn':
            object[name] = screens;
            break;
          case 'WebsitePageComponentParamError':
            object[name] = errors;
            break;
          case 'WebsitePageComponentParamFilesForScreens':
            object[name] = filesScreens;
            break;
          case 'WebsitePageComponentParamSizes':
            object[name] = sizes;
            break;
          case 'WebsitePageComponentParamImagesGroup':
            object[name] = images;
            break;
          case 'WebsitePageComponentParamPackageFormInputs':
            object[name] = inputs;
            break;

          default:
            throw new Error(`Error: RenderComponent __typename: ${__typename} not determined`);
        }
      } catch (error) {
        // Error
      }
    }
    return object;
  }
  return null;
}

export const getComponentParams = (component: ICdekComponent) => {
  const { params } = component;

  if (component.__typename === 'rest') {
    return params;
  }

  return convertPageArrayToObject(params);
};

export const getPageData = (res: IWebsitePageData) => {
  return {
    page: {
      content: res?.content,
      components: res?.components,
      metaTags: res?.metaTags,
    },
    metaData: prepareRestApiMetaData(res),
  };
};
